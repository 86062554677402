import React from 'react'

class Home extends React.Component {
  render() {
      return (
          < >
              <div className="paper container margin-bottom-small">
                  <p className="text-lead">Firma tegeleb raudteede ehituse ja remontiga alates 1999.a.</p>
                  <p>
                      Raudteerajatise ehitamine. Raudteede hooldus. Üldehitus.
                  </p>
                  <p>
                      Kehtib kvaliteedijuhtimissüsteemi sertifikaat vastavalt ISO9001:2015 standardile.
                      {/*<a href="images/juhtimissert.23.1.jpg" className="image-link smashing-link">*/}
                      {/*    <>ISO 9001:2015 sertifikaat </>*/}

                          {/*<img src="./images/juhtimissert.23.tn-sq.jpg"*/}
                          {/*     title="ISO 9001:2015 sertifikaat"*/}
                          {/*     alt="ISO 9001:2015 sertifikaat"*/}
                          {/*     width="100rem" className="shadow "*/}
                          {/*/>*/}
                      {/*</a>*/}

                  </p>

              </div>

              <div className='paper container margin-bottom-small'>
                  <h4>Meie pakutavad teenused:</h4>
                  <ol>

                      <li> Raudteede ehitus, remont ja hooldus</li>
                      <li> Ülesõidukohtade ning pöörangute käsitsi lumest puhastamine</li>
                      <li>
                          Raudtee ülesõidukohtade ja kergeliikluse ülekäigukohtade ehitamine<br/>
                      </li>
                      <li>
                          Raudtee pealisehitise materjalide müük<br/>
                      </li>
                      <li>
                          Vastutava isiku (raudtee teemehaanik III ja II) teenused<br/>
                      </li>
                      <li>
                          Raudteede demonteerimine<br/>
                      </li>
                      <li>
                          Raudtee kaitsevööndis üldehitustööd<br/>
                      </li>
                      <li>
                          Kraanateede ehitus-, remondi- ja hooldustööd<br/>
                      </li>
                      <li>
                          Haruraudteede seisundi hindamine<br/>
                      </li>
                      <li>
                          Raudteede ja pöörmete lumest puhastamine raudteeekskavaatoriga<br/>
                      </li>

                  </ol>
                  {/*<div className="clearer"></div>*/}

              </div>


              {/*<div className='paper container margin-bottom-small'>*/}
              {/*    <table>*/}
              {/*        <tbody>*/}
              {/*        <tr>*/}
              {/*            <td>Reg.nr.</td>*/}
              {/*            <td>10608381</td>*/}
              {/*            <td>Aadress:</td>*/}
              {/*            <td>Näituse 26, 50407 Tartu</td>*/}
              {/*        </tr>*/}
              {/*        <tr>*/}
              {/*            <td>KMRK nr.</td>*/}
              {/*            <td>EE100581238</td>*/}
              {/*            <td>Tel.</td>*/}
              {/*            <td>(+372) 7 420 535</td>*/}
              {/*        </tr>*/}
              {/*        <tr>*/}
              {/*            <td>e-mail:</td>*/}
              {/*            <td>ferrander@gmail.com</td>*/}
              {/*        </tr>*/}
              {/*        </tbody>*/}
              {/*    </table>*/}
              {/*</div>*/}

              <div className='paper container margin-bottom-small'>
                  <table>
                      <tbody>
                      <tr>
                          <td>Aadress:</td>
                          <td>Näituse 26, 50407 Tartu</td>
                          <td>Reg.nr.</td>
                          <td>10608381</td>
                      </tr>
                      <tr>
                          <td>Telefon:</td>
                          <td>(+372) 7 420 535</td>
                          <td>KMRK nr.</td>
                          <td>EE100581238</td>
                      </tr>
                      <tr>
                          <td>e-mail:</td>
                          <td>ferrander@gmail.com</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </>

      )
  }
}

export default Home

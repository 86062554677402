import React from 'react'

export default class Tooted extends React.Component {
  years = [
    {
      y: 2023,
      a: [
        "haruraudteede remont (Narva, Kohtla-Järve)",
        "Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)",
        "Muuga sadama raudteede ja ülesõidukohtade remont (Muuga)"
      ]
    },
    {
      y:2022,
      a: [
        'haruraudteede remont (Tartu, Kohtla-Järve)',
        'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
        'Eesti Raudtee KB tüüpi rööpakinnituste hooldustööd (üle Eesti)',
        'Eesti Raudtee pöörmeprusside üksikvahetus (üle Eesti)'
      ]
    },
    {y:2021,
    a: [
      'haruraudteede remont (Tapa, Kehra)',
      'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
      'Eesti Raudtee rööpakinnituste korrashoiu teostamine (Põlvamaa)',
      'Eesti Raudtee liiprite vahetamine (Lõuna-Eesti)',
      'Lelle-Pärnu liiprite vahetamine (Pärnumaa)'
    ]},
    {y:2020,
      a:[
        'haruraudteede remont (Kohtla-Järve, Jõhvi)',
        'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
        'Eesti Raudtee rööpakinnituste korrashoiu teostamine (üle Eesti)',
        'Eesti Raudtee liiprite ja pöörmeprusside vahetamine (Muuga Sadam)'
      ]},
    {y:2019, a:[
      'haruraudteede remont (Narva, Kohtla-Järve, Rakke, Jõhvi)',
      'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
      'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
      'Eesti Raudtee liiprite ja pöörmeprusside vahetamine (Muuga-Maargu-Lagedi)'
    ]},
       {y:2018, a:[
    'haruraudteede remont (Narva, Kohtla-Järve, Rakke)',
    'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
    'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
    'Eesti Raudtee liiprite vahetamine (Aegviidu-Tapa)',
  ]},
     {y:2017 , a: [
    'VKG territooriumil haruraudteede remont (Kohtla-Järve)',
    'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
    'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
    'Eesti Raudtee liiprite vahetamine (üle Eesti)',
  ]}
     , { y:2016, a: [
  'raudtee toppimistööd (üle Eesti)',
  'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
  'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
  'Eesti Raudtee liiprite vahetamine (üle Eesti)',
  '„Fastclip“ kinnituste vahetuse tööd (Tartu-Tapa-Narva)',
  '\'Tallinna Sadam AS\' Muuga sadama raudteede ja ülesõidukohtade remont (Muuga)',
]}
  , { y:2015, a: [
  'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
  'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
  'Eesti Raudtee liiprite vahetamine ja rööpakinnituse hooldustööd (üle Eesti)',
  '\'Tallinna Sadam AS\' Muuga sadama raudteede ja ülesõidukohtade remont (Muuga)',
]}
  , { y:2014, a:[

  'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti)',
  'Eesti Raudtee rööbasteede mehhaniseeritud lumetõrje ekskavaatoriga (Paldiski-Riisipere-Tallinn)',
  'Eesti Raudtee liiprite ja prusside vahetamine (Tallinn, Tartu)',
  'haruraudteede remont (Narva, Rakke, Tallinn, Rakvere)',
  'Fastclip kinnituste vahetuse tööd (Ida-Virumaa)',
]}
  , { y:2013, a:[
  'Eesti Raudtee käsitsi lumetõrjetööd (üle Eesti 10 jaamades)',
  'raudtee demontaaz (Maidla)',
  'Eesti Raudtee sõrestike demontaaz (Tallinn)',
  'haruraudteede remont (Vaivara, Narva, Rakke, Kohtla-Järve, Tallinn)',
  'EVR Cargo pöördsildade remont (Tartu, Valga)',
  'Kopli jaamas jääkreostuse likvideerimisel rauteetööd (Tallinn)',
]}

, { y:2012,
  a:[
  'EVR käsitsi lumetõrjetööd (üle Eesti 20 jaamades)',
  'Tallinna Sadama tööstuspargis raudtee ehitus (Muuga)',
  'EVR liiprite vahetus (Tallinn-Paldiski, Tapa-Tartu, Aegviidu-Tapa)',
  'haruraudteede remont (Rakke, Kohtla-Järve, Rakvere)',
  'Pärnamäe ülekäigukoha ehitamine (Tallinn)',
]}

  , { y:2011, a: [
  '\'Ühinenud Depood AS\' haruraudteede remont (Tallinn, Tartu)',
  'EVR liiprite vahetus (Tallinn-Tapa, Tapa-Tartu, Võru)',
  'raudtee jalakäija ülekäigukohtade ehitamine (Tallinn-Aegviidu)',
  'EVR käsitsi lumetõrjetööd (Tallinn)',
  'kraanatee ehitamine (Koidula)',
  'ohutu rongiliikluse tagamine (Lagedi, Tabivere, Veriora)',
  'Tallinna Tehnikaülikooli remonditööd s.h. rektoraadi remont (Tallinn)',
]}
  , { y:2010, a: [
  'Tartu reisijate ooteplatvormi ehitamine (Tartu)',
  'EVR sõrestiku demonteerimine (Tallinn)',
  'ohutu rongiliikluse tagamine (Tallinn, Tartu, Lääne-Virumaa)',
  'Tallinna Tehnikaülikooli remonditööd (Tallinn)',
]}
  , { y: 2009, a: [
  'DBT ja Tallinna Sadama ülesõidu remont (Muuga)',
  'EVR liiprite vahetus (Lõuna piirkond)',
  '"Euro platvormide" jaoks raudtee remont (Riisipere-Keila-Paldiski)',
]}
, { y:2008, a: [
  '"Mereringtee" raudtee demonteerimine (Tallinn)',
  'Eesti Draamateatri rekonstrueerimine (Tallinn)',
  '20km pearaudteel kinnituste vahetamine (Kohtla-Järve-Püssi/Jõgeva-Tapa)',
  '\'Alekon Holding AS\' vanaraudtee remont ja uue raudtee ehitus (Maardu)',
]}
  , { y:2007, a: [
  'reisijate ooteplatvormi renoveerimine (Tartu)',
  '\'Agrochema Eesti OÜ\' kotorihoone renoveerimine (Jõgeva)',
  'betoontehase uuete raudteede kaevetööd (Muuga)',
  '\'VKG Transport AS\' territooriumil raudtee ülesõidukohtade remont (Kohtla-Järve)',
]}
  , { y:2006, a: [
  '\'Agrochema Eesti OÜ\' haruteede remont (Jõgeva)',
  '\'VKG Transport AS\' territooriumil raudtee ülesõidukohtade remont (Kohtla-Järve)',
  '\'SIA Agrochema Latvija\' haruteede remont (Jecava)',
  'Eesti Raudtee raudteede remont (Orava, Muuga, Maardu, Tartu, Reola)',
]}
  , { y:2005,
 a: [
  'Kirsi reisiplatvormi ehitamine (Tartu)',
  'Tartu raudteejaama ajutise vedurite tanka ehitamine',
  'Vedurite ja vagunite tehnohooldehalli osaline rekonstrueerimine (Tartu)',
  'Eesti Raudtee raudteede remont (Tabivere, Tartu, Muuga)',
]}
  , { y:2004,
 a: [
  'haruraudteede ehitamine (Valga)',
  'raudtee demonteerimine (Haapsalu-Riisipere)',
  '\'Nordkalk AS\' haruteede remont (Rakke)',
  'teedevahelise raudtee reisiplatvorvi ehitamine (Jõgeva)',
  'Eesti Raudtee raudteede remont (Nõmmküla-Tamsalu, Tartu-Kärkna)',
]}
  , { y:2003, a: [

  '\'Kreenholmi Valduse AS\' haruteede remont (Narva)',
  'Paljasssaare kaide haruteede avariiremont (Tallinn)',
  'Eesti Raudtee raudteede remont (Tartu, Tapa-Lehtse-Aegviidu)',
]}
  , { y:2002, a: [
  'Paljasssaare kaide haruteede remont ja rekonstrueerimine (Tallinn)',
  'Pääsküla vagunidepoo hoolduskanali remont (Tallinn)',
  'Variku, Aiamaa ja Mõneku raudteepeatuste remont',
  'Muuga sadama kraanateede remont',
  'Tartu raudtee jaama pöörmete ja haruteede remonditööd',
]}
  , { y:2001, a: [

  'Aakre, Mägiste ja Vapramäe raudteepeatuste remont',
  'raudteesilla remont',
  'raudteede demontaazitööd',
  'Paljassaare sadama raudteede hooldustööd (Tallinn)',
]},
    {y:2000, a: [
      'Tapa veduridepoo haruraudteede ümberehitus',
      'Muuga sadama raudteede ja raudteeülesõidukohtade remonditööd',
      'Muuga sadama kaidel asuvate haruraudteede rekonstrueerimine'
    ]},
    { y:1999, a: [
      'Raudtee kapitaal remont (Tapa-Narva)',
      'Muuga sadama raudtee remont'
    ]},
  ];

  render() {
    let blocks = this.years.map((year) => {
      return (
        // <div key={year.y}>
          <tr>
            <td valign="top" align="right" className="col "><i >{year.y}</i></td>
            <td className="col ">
              {
                year.a.map((i) => (
                    <span>&#8210; {i}<br/></span>
                    // <span key={i}> * {i}<br/></span>
                ))
              }
            </td>
          </tr>


      )
    });


    {/*  <div className="collapsible">*/}
    {/*  <input id={"collapsible.y" + year.y} type="checkbox" name="collapsible"*/}
    {/*         defaultChecked={year.y === 2021 || year.y === 2022 || year.y === 2023 }*/}
    {/*          />*/}
    {/*  <label htmlFor={"collapsible.y"+year.y}>*/}
    {/*    <h4>{year.y}:</h4>*/}
    {/*  </label>*/}
    {/*  <div className="collapsible-body">*/}
    {/*    <span>*/}
    {/*      { year.a.map((i) => (<span key={i}> * {i}<br/></span>)) }*/}
    {/*    </span>*/}
    {/*  </div>*/}
    {/*</div>*/}

    return (
        <div>
          <table>
            <tbody>
            {blocks}

            </tbody>
          </table>
        </div>
    )
  }
}
